//
//
//
//
//
//
//
//
//

import Vue from "vue";
import { Swiper as SwiperClass, Autoplay } from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

SwiperClass.use([Autoplay]);

Vue.use(getAwesomeSwiper(SwiperClass));

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";


export default {

	name: 'Carousel',

	components: {
		Swiper,
		SwiperSlide
	},

	props: {
		carouselId: {
			type: String,
			default: () => {}
		}
	},

	data() {
		return {
			carouselImgs: [
				require('~/assets/images/70850018-2721181141245950-5741574919095844864-n.jpg'),
        require('~/assets/images/huckebein-location-02.jpg'),
        require('~/assets/images/huckebein-03-900x500.jpg'),
			],
			swiperOption: {
				centeredSlides: false,
				spaceBetween: 30,
				grabCursor: true,
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: false
				},
				breakpoints: {
					1000: {
						slidesPerView: 1
					},
					760: {
						slidesPerView: 1
					}
				}
			}
		};
	},

	methods: {
/*		setUrlImage(img) {
			if (img.includes("http")) {
				return img;
			} else {
				//return require(`~/assets/${img}`);
			}
		}*/
	},

}
