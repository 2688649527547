//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

  name: 'Home',

  scrollToTop: false,

  head() {
    return {
      title: 'Club Huckebein - Der Kultclub in Darmstadt - seit 1974',
      titleTemplate: '%s'
    }
  },

}
