//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapGetters} from 'vuex';

export default {

	name: 'UpcomingEvents',

	props: {
	},

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters('events', ['upcoming']),

    upcomingEvents() {
      return this.upcoming.slice(0, 2)
    }
  }

}
