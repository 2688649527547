//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { BImgLazy } from 'bootstrap-vue'

export default {

	name: 'Card',

  components: { BImgLazy },

  props: {
		title: {
			type: String,
			default: () => {}
		},
		info: {
			type: String,
			default: () => {}
		},
		day: {
			type: String,
			default: () => {}
		},
		month: {
			type: String,
			default: () => {}
		},
		time: {
			type: String,
			default: () => {}
		},
		img: {
			type: String,
			default: () => {}
		},
		linkTarget: {
			type: String,
			default: 'event'
		},
    lazy: {
		  type: Boolean,
      default: false
    },
    slug: {
      type: String,
      default: ''
    },
		eventId: {
			type: Number,
			default: () => {}
		},
		height: {
			type: String,
			default: () => {}
		},
		overlay: {
			type: Boolean,
			default: () => {}
		},
		overlayType: {
			type: String,
			default: () => {}
		}
	},

  computed: {

	  linkRoute() {

	    if(this.linkTarget === 'event') {
	      return 'event.detail'
      }

      if(this.linkTarget === 'gallery') {
        return 'event.gallery'
      }

    },

    targetContentClass() {

      if(this.linkTarget === 'gallery') {
        return 'card-content-gallery'
      }

      return ''
    }

  }

}
