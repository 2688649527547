//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'Tour',
	props: {
		colxl: {
			type: String,
			default: () => {}
		}
	}
}
