import { render, staticRenderFns } from "./HeaderImage.vue?vue&type=template&id=e47a6af4&"
import script from "./HeaderImage.vue?vue&type=script&lang=js&"
export * from "./HeaderImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Carousel: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/carousel/Carousel.vue').default})
