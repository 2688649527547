//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";

import { Swiper as SwiperClass, Autoplay, Lazy } from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

SwiperClass.use([Autoplay, Lazy]);

Vue.use(getAwesomeSwiper(SwiperClass));

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";


export default {

	name: 'Gallery',

	components: {
		Swiper,
		SwiperSlide
	},

	data() {
		return {
			swiperOption: {
        preloadImages: false,
        lazy: {
          checkInView: true,
          loadPrevNext: true,
        },
				centeredSlides: false,
				spaceBetween: 30,
				grabCursor: true,
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: true,
          pauseOnMouseEnter: true,
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				},
				/*breakpoints: {
					1000: {
						slidesPerView: 1
					},
					760: {
						slidesPerView: 1
					}
				}*/
			},
		}
	},

	methods: {
		slidePrev() {
			return this.$refs.swiper.swiperInstance.slidePrev();
		},
		slideNext() {
			return this.$refs.swiper.swiperInstance.slideNext();
		},
    handleClickSlide(index, reallyIndex) {
      this.$store.commit('events/SET_GALLERY_INDEX', reallyIndex)
      this.$router.push({name: 'event.gallery', params: {id: this.lastGalleryEventId, slug: this.lastGalleryEventSlug}})
    }
	},

  computed: {

    lastGallery() {
      return this.$store.state.events.last_gallery.images
    },

    lastGalleryEventId() {
      return this.$store.state.events.last_gallery.event_id
    },

    lastGalleryEventSlug() {
      return this.$store.state.events.last_gallery.event_slug
    },

  },

}
