import { render, staticRenderFns } from "./UpcomingEvents.vue?vue&type=template&id=600d7776&"
import script from "./UpcomingEvents.vue?vue&type=script&lang=js&"
export * from "./UpcomingEvents.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Card: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/base/card/Card.vue').default})
