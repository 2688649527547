import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9d87c2d4&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UpcomingEvents: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/events/UpcomingEvents.vue').default,HeaderImage: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/base/header/HeaderImage.vue').default,Gallery: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/gallery/Gallery.vue').default,Tour: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/tour/Tour.vue').default})
